import { Stripe } from 'stripe';
import { API } from 'src/utils/AmplifyApiUtils';
import { AppAPIName, SubscriptionStatus } from 'src/constants';
import { ApiMethods, ApiTags, appAPI } from '.';
import { SubscriptionEntity } from 'src/store/payments/types';
import {
  deleteSubscriptionsAction,
  updateSubscriptionsAction,
} from 'src/store/payments/actions';
import {
  Subscription,
  SubscriptionCollectionMethod,
  SubscriptionFields,
  SubscriptionSchema,
  SubscriptionTemplateSchema,
} from 'src/types/subscriptions';
import { LineItem } from 'src/legacy/components/billing/new/types';
import { notify } from 'src/clients/ApiService';
import history from 'src/history';
import { SUBSCRIPTION_PAGE } from 'src/constants';
import { ensureApiError } from 'src/utils/Errors';

export type LineItemWithRate = LineItem & {
  rate: number;
};

type SubscriptionMetaData = Pick<
  SubscriptionFields,
  'taxPercentage' | 'memo' | 'interval' | 'attachment'
> & {
  lineItems: LineItemWithRate[];
};

export type SubscriptionTemplate = {
  id: string;
  additionalFields: {
    templateName: string;
  };
  fields: SubscriptionMetaData;
};

type SubscriptionFieldsWithRateLineItem = Omit<
  SubscriptionFields,
  'lineItems'
> & {
  lineItems: LineItemWithRate[];
};

type SubscriptionWithRateLineItem = Omit<Subscription, 'fields'> & {
  fields: SubscriptionFieldsWithRateLineItem;
};

type UpdateSubscriptionTemplatePayload = SubscriptionTemplateSchema & {
  id: string;
};

type ListSubscriptionResponse = {
  data: Subscription[];
  nextToken?: string;
};

async function loadSubscriptions(
  previousItems: Subscription[],
  nextToken?: string,
): Promise<Subscription[]> {
  const url = '/subscriptions';
  const response: ListSubscriptionResponse = await API.get(AppAPIName, url, {
    queryStringParameters: {
      limit: 1000,
      nextToken,
    },
  });

  const responseItems = response?.data ? response.data : [];
  const updatedItems = [...previousItems, ...responseItems];

  if (!response.nextToken) {
    return updatedItems;
  }

  return loadSubscriptions(updatedItems, response.nextToken);
}

type UpdateSubscriptionPayload = SubscriptionSchema & {
  id: string;
};

export const subscriptionsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionEntityById: build.query<
      SubscriptionEntity,
      Pick<SubscriptionEntity, 'id'>
    >({
      query: ({ id }) => ({
        path: `/v0/subscriptions/entity/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    getNextInvoiceSummary: build.query<Stripe.Invoice, any>({
      query: ({ id, ...subscription }) => ({
        path: `/v0/subscriptions/${id}/next-invoice`,
        method: ApiMethods.post,
        options: { body: subscription },
      }),
    }),
    markSubscriptionDeleted: build.mutation<
      void,
      { id: string; subscriptions: SubscriptionEntity[] }
    >({
      query: ({ id }) => ({
        path: `/v0/subscriptions/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      onQueryStarted({ subscriptions, id }, { dispatch, queryFulfilled }) {
        const filteredSubscription = subscriptions.filter(
          (sub: SubscriptionEntity) => sub.id === id,
        );
        dispatch(deleteSubscriptionsAction(filteredSubscription));
        queryFulfilled.catch(() =>
          dispatch(updateSubscriptionsAction(subscriptions)),
        );
      },
    }),
    getSubscriptionById: build.query<SubscriptionWithRateLineItem, string>({
      query: (id) => ({
        path: `/subscriptions/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    getSubscriptions: build.query<Subscription[], void>({
      queryFn: async () => {
        const response = await loadSubscriptions([]);
        return {
          data: response ?? [],
        };
      },
      providesTags: [ApiTags.invoices],
    }),
    createSubscription: build.mutation<Subscription, SubscriptionSchema>({
      query: (subscription) => ({
        path: '/subscriptions',
        method: ApiMethods.post,
        options: {
          body: {
            ...subscription,
            fields: {
              ...subscription.fields,
              taxPercentage: Number(subscription.fields.taxPercentage),
              daysUntilDue:
                subscription.fields.collectionMethod ===
                SubscriptionCollectionMethod.SendInvoice
                  ? Number(subscription.fields.dueDays)
                  : null,

              lineItems: subscription.fields.lineItems.map((item) => ({
                ...item,
                rate: Number(item.amount),
                quantity: Number(item.quantity),
              })),
            },
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const subscription = result.data;
          dispatch(
            subscriptionsApi.util.updateQueryData(
              'getSubscriptions',
              undefined,
              (draft) => {
                draft.unshift(subscription);
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Subscription is created successfully.',
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Subscription could not be created.',
            error,
            dispatch,
          });
        }
      },
    }),
    updateSubscription: build.mutation<Subscription, UpdateSubscriptionPayload>(
      {
        query: (subscription) => ({
          path: `/subscriptions/${subscription.id}`,
          method: ApiMethods.put,
          options: {
            body: {
              ...subscription,
              fields: {
                ...subscription.fields,
                taxPercentage: Number(subscription.fields.taxPercentage),
                lineItems: subscription.fields.lineItems.map((item) => ({
                  ...item,
                  rate: Number(item.amount),
                  quantity: Number(item.quantity),
                })),
              },
            },
          },
        }),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;
            const subscription = result.data;
            dispatch(
              subscriptionsApi.util.updateQueryData(
                'getSubscriptions',
                undefined,
                (draft) => {
                  if (subscription) {
                    const index = draft.findIndex(
                      (i) => i.id === subscription.id,
                    );
                    if (index == -1) {
                      draft.unshift(subscription);
                    } else {
                      draft.splice(index, 1, subscription);
                    }
                  }
                },
              ),
            );

            dispatch(
              subscriptionsApi.util.updateQueryData(
                'getSubscriptionById',
                subscription.id,
                (draft) => {
                  draft.fields = subscription.fields;
                },
              ),
            );

            notify({
              status: 'success',
              successMessage: 'Subscription is updated successfully.',
              dispatch,
            });
          } catch (err) {
            const error = ensureApiError(err);
            const errorMessage = error.message;
            notify({
              status: 'error',
              errorMessage:
                errorMessage || 'Subscription could not be updated.',
              error,
              dispatch,
            });
          }
        },
      },
    ),
    deleteSubscription: build.mutation<void, string>({
      query: (id) => ({
        path: `/subscriptions/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          subscriptionsApi.util.updateQueryData(
            'getSubscriptions',
            undefined,
            (draft) => {
              const index = draft.findIndex((i) => i.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Subscription is deleted successfully.',
            dispatch,
          });
        } catch (err) {
          patchResult.undo();
          const error = ensureApiError(err);
          const errorMessage = error.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Subscription could not be deleted.',
            error,
            dispatch,
          });
        }
      },
    }),
    cancelSubscription: build.mutation<void, string>({
      query: (id) => ({
        path: `/subscriptions/${id}/cancel`,
        method: ApiMethods.post,
        options: {},
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          subscriptionsApi.util.updateQueryData(
            'getSubscriptions',
            undefined,
            (draft) => {
              const index = draft.findIndex((i) => i.id === id);
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  additionalFields: {
                    ...draft[index].additionalFields,
                    status: SubscriptionStatus.Canceled,
                  },
                };
              }
            },
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Subscription is cancelled successfully.',
            dispatch,
          });
        } catch (err) {
          patchResult.undo();
          const error = ensureApiError(err);
          const errorMessage = error.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'Subscription could not be cancelled.',
            error,
            dispatch,
          });
        }
      },
    }),
    getSubscriptionTemplates: build.query<SubscriptionTemplate[], void>({
      query: () => ({
        path: '/entities/stripe_subscription',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            isTemplateQuery: true,
          },
        },
      }),
      providesTags: [ApiTags.templates],
    }),
    createSubscriptionTemplate: build.mutation<
      SubscriptionTemplate,
      SubscriptionTemplateSchema
    >({
      query: (data) => {
        const { fields } = data;
        return {
          path: `/subscription-templates`,
          method: ApiMethods.post,
          options: {
            body: {
              fields: {
                ...fields,
                lineItems: fields.lineItems.map((lineItem) => ({
                  ...lineItem,
                  id: '',
                  rate: Number(lineItem.amount),
                  quantity: Number(lineItem.quantity),
                })),
                taxPercentage: Number(fields.taxPercentage),
              },
              additionalFields: {
                templateName: data.additionalFields.templateName,
              },
            },
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            subscriptionsApi.util.updateQueryData(
              'getSubscriptionTemplates',
              undefined,
              (draft) => {
                draft.push(result.data);
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Template is saved and available for future use.',
            dispatch,
          });
          history.push(SUBSCRIPTION_PAGE.path);
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error?.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'Subscription template could not be created.',
            error,
            dispatch,
          });
        }
      },
    }),
    updateSubscriptionTemplate: build.mutation<
      SubscriptionTemplate,
      UpdateSubscriptionTemplatePayload
    >({
      query: (data) => {
        const { fields, id } = data;

        return {
          path: `/subscription-templates/${id}`,
          method: ApiMethods.put,
          options: {
            body: {
              fields: {
                ...fields,
                lineItems: fields.lineItems.map((lineItem) => ({
                  ...lineItem,
                  id: '',
                  rate: Number(lineItem.amount),
                  quantity: Number(lineItem.quantity),
                })),
                taxPercentage: Number(fields.taxPercentage),
              },
              additionalFields: {
                templateName: data.additionalFields.templateName,
              },
            },
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            subscriptionsApi.util.updateQueryData(
              'getSubscriptionTemplates',
              undefined,
              (draft) =>
                draft.map((template) => {
                  if (template.id === result.data.id) {
                    return result.data;
                  }

                  return template;
                }),
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Template is saved and available for future use.',
            dispatch,
          });
          history.push(SUBSCRIPTION_PAGE.path);
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'This Subscription Template could not be saved.',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionEntityByIdQuery,
  useGetNextInvoiceSummaryQuery,
  useMarkSubscriptionDeletedMutation,
  useGetSubscriptionByIdQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useGetSubscriptionTemplatesQuery,
  useCreateSubscriptionTemplateMutation,
  useUpdateSubscriptionTemplateMutation,
  useDeleteSubscriptionMutation,
  useCancelSubscriptionMutation,
} = subscriptionsApi;
